import Vue from "vue";
//import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import VBodyScrollLock from 'v-body-scroll-lock'
import Components from "./components";
import Plugins from './plugins';
import "./css/main.scss";
import VueAwesomeSwiper from 'vue-awesome-swiper'

// import style
import 'swiper/css/swiper.css'

Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

import EventBus from './EventBus'

Vue.config.productionTip = true;

Vue.use(VBodyScrollLock)

new Vue({
  el: "#app",
  delimiters: ["[[", "]]"],
  data() {
      return {
          csrfName: window.csrfTokenName,
          csrfToken: window.csrfTokenValue,
          mainScroll: false,
          swiperOptions: {
            loop: true,
            autoplay: {
               delay: 5000,
             },
            loopedSlides: 5, // looped slides should be the same
            spaceBetween: 10,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            },
            pagination: {
              el: '.swiper-pagination',
              type: 'bullets',
            },
            themeColor: '#fff'
          },
      }
  },
  mounted: function() {
      EventBus.$on('craft:post', this.craftPostHandler)
      //EventBus.$on('craft:noscroll', this.stopMainScroll)
      //EventBus.$on('craft:scroll', this.allowMainScroll)

      // this.body = document.getElementsByTagName('body')[0]
      // this.html = document.getElementsByTagName('html')[0]
      // this.element = document.querySelector('.popup-inner');
  },
  methods: {
      craftPostHandler(req) {
          console.log("Submitting form")
          if (!req.action) {
              throw "Craft POST must contain a valid action";
          }
          let form = this.buildForm(req.data, req.action);
          fetch('/', {
              method: 'POST',
              body: form,
              /*JSON.stringify({
                  ...req.data,
                  action: req.action,
                  [this.csrfName]: this.csrfToken
              }),*/
              headers: {
                  'Accept': 'application/json'
              }
          })
          .then(resp => {
              if (req.callback) {
                  req.callback(resp)
              }
          });
      },
      buildForm(data, action) {
          let form = new FormData();
          Object.keys(data).forEach((key) => {
              form.set(key, data[key]);
          })
          form.set(this.csrfName, this.csrfToken);
          form.set('action', action)
          return form
      },
      /*closeModal() {
            this.$set(this, 'modalIsOpen', false)
        },
        openModal() {
            this.$set(this, 'modalIsOpen', true)
        },*/
      // stopMainScroll(element) {
      //     disableBodyScroll(this.element);
      // },
      // allowMainScroll(element) {
      //     enableBodyScroll(this.element);
      // },
  },
  // beforeDestroy() {
  //     clearAllBodyScrollLocks();
  // }
});

// 
// window.onload=function(){
//     var elem = document.getElementById("loader");
//     elem.parentNode.removeChild(elem);
// }

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);
