<template>
<div class="navbar top-0 w-full" :class="{ 'z-40': !popup, 'navbar--hidden': !showNavbar, 'top': top }">
  <slot></slot>
</div>
</template>

<script>
import EventBus from '../EventBus';

export default {
  data() {
    return {
      showNavbar: true,
      lastScrollPosition: 0,
      top: true,
      popup: false,
    }
  },
  mounted() {
    window.addEventListener('scroll',
      this.onScroll)
    EventBus.$on('craft:noscroll', () => {
      this.popup = true
    })
    EventBus.$on('craft:scroll', () => {
      this.popup = false
    })
  },
  beforeDestroy() {
    window.removeEventListener('scroll',
      this.onScroll)
  },

  methods: {
    onScroll() {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop

      if (currentScrollPosition < 0) {
        return
      }

      if (currentScrollPosition < 100) {
        this.top = true
      } else {
        this.top = false
      }
      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset

      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
        return
      }

      this.showNavbar = currentScrollPosition < this.lastScrollPosition
      this.lastScrollPosition = currentScrollPosition
    }
  }
}
</script>

<style>
.navbar {
  width: 100vw;
  background: #1b2028;
  position: fixed;
  @apply text-blue-600;
  transform: translate3d(0, 0, 0);
  transition: 0.1s all ease-out;
}

.navbar.top {
  position: absolute;
  background: transparent;
  color: white;
  transition: 0.5s background, color;
}

.navbar.navbar--hidden {
  box-shadow: none;
  transform: translate3d(0, -100%, 0);

}
</style>
