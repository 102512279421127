<template>
    <Slide noOverlay right :crossIcon="true" >
      <div v-for="navItem in mainNodes">
        <a id="home" :href="navItem.url" class="text-white hover:font-bold hover:text-white">
          <span>{{ navItem.title }}</span>
        </a>
      </div>
    </Slide>
</template>

<script>
import { Slide } from 'vue-burger-menu'  // import the CSS transitions you wish to use, in this case we are using `Slide`

export default {
  props:{
    mainNodes: Array,
  },
    components: {
        Slide // Register your component
    }
}
</script>
