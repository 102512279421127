<template>
<div>
  <div class="hideit" v-on:click="show">{{ title }}</div>
  <popup-modal ref="modal" :show="showModal" v-on:close="hide">
    <slot></slot>
  </popup-modal>
</div>
</template>

<script>
import PopupModal from './PopupModal'
import EventBus from '../EventBus'

export default {
  props: {
    title: String,
  },
  components: {
    PopupModal,
  },
  data() {
    return {
      showModal: false,
    }
  },
  methods: {
    show() {
      this.showModal = true
      EventBus.$emit('craft:noscroll', this.$refs.modal)
    },
    hide() {
      this.showModal = false
      EventBus.$emit('craft:scroll', this.$refs.modal)
    },
  }
}
</script>
